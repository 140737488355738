import React, { useReducer, useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { notification, InputNumber } from 'antd';


import { isValidEmail } from '../../helper';
import { setBreadcrumbAction } from '../../store/ui';
import {
    addCampAction,
    clearLoaderAction,
    campModeAction,
    campDetailAction,
    clearCampDetailAction,
    editCampAction,
    personnalByCampCodeAction
} from '../../store/camps';
import { directorDelete } from '../../services/campService';
import CampProgramList from './CampProgramList';
import { MainLoader } from '../reuse/SVG';
import ConfirmModal from '../modals/ConfirmModal';
import { CustomNumber } from '../util/FormControls';


const Camp = props => {
    const url = props.match.params.id;
    
    const [disableAddDirctor, setDisableAddDirctor] = useState(true);
    const [resError, setResError] = useState('');
    const [editRoute, setEditRoute] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentId, setCurrentId] = useState('');
    const [isNewDirector, setIsNewDirector] = useState(false);

    const [achTransferRate, setAchTransferRate] = useState({
        status: 1,
        percentage: 0,
        taxRate: 0,
        inputErr: '',
    });
    const [byCheque, setByCheque] = useState({
        status: 1,
        taxRate: 0,
        inputErr: '',
    });

    const [showInsurance, setShowInsurance] = useState(false)
    const [showProductEditButton, setshowProductEditButton] = useState(false)

    const [creditCardRate, setCreditCardRate] = useState({
        status: 1,
        percentage: 0,
        taxRate: 0,
        inputErr: '',
    });
    const [splitPaymentRate, setSplitPaymentRate] = useState({
        status: 1,
        percentage: 0,
        taxRate: 0,
        noOfTimesPayment: 1,
        inputErr: '',
    });


    const [userInput, setUserInput] = useReducer((state, newState) => ({...state, ...newState}),
        {
            campName: '',
            campCode: '',
            email: '',
            phone: '',
            address: '',
            campDirector: [],
            icon: '',
            iconPreview: '',
            registrationTarget: '',

            directorName: '',
            directorEmail: '',
            directorPhone: '',
            directorAddress: '',
            isRedirect: false,
            newCampId: '',
            numberOfProgram: ''
        }
    );

    const [error, setError] = useReducer((state, newState) => ({...state, ...newState}),
        {
            campName: '',
            email: '',
            phone: '',
            address: '',
            icon: '',
            directorName: '',
            directorEmail: '',
            registrationTarget: '',
        }
    );

    


    let params = {
        icon: 'camps',
        links: [
            {name: 'Providers', path: '/camps'},
            {name: 'Add Provider', path: ''}
        ]
    }


    useEffect(() => {
        let campCode = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < 7; i++){
            campCode += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setUserInput({campCode})
      }, [])


    const handleConfirmModal = (id, index) => {
        if(id && id.length > 10){
            setCurrentId(id);
            setConfirmModal(!confirmModal);
        }else{
            let tempCampDirector = [...userInput.campDirector];
            tempCampDirector.splice(index, 1);
            setUserInput({campDirector: tempCampDirector})
        }
        

        
    }


    const handleDelete = () => {
        if(currentId){
            let params = { campDirectorId : currentId };
            directorDelete(params).then(status => {
            setCurrentId('');
            let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
            props.campDetailAction(query);
            setConfirmModal(false);
        }); 
    }else {
        setCurrentId('');
        setConfirmModal(false);
    }
        
    }



    const handleChange = e => {
        setUserInput({[e.target.name]: e.target.value});
        setError({[e.target.name]: ''});
        setResError('');

        if(userInput.directorName != '' && userInput.directorEmail != ''){
            setDisableAddDirctor(false)
        }else{
            setDisableAddDirctor(true)
        }
    }


    const handleRegistrationTarget = e => {
        setUserInput({registrationTarget: e});
        setError({registrationTarget: ''});
    }
    




    const handlePhoneChange = (phone, name) => {
        setUserInput({[name]: phone});
        setError({[name]: ''});
    }
    



    const handleAddDirector = () => {

        let { directorName, directorEmail, directorPhone, directorAddress } = userInput;

        if(directorName != '' && directorEmail != ''){
            if(directorEmail){
                setIsNewDirector(true);
                setUserInput({
                    campDirector: [
                        ...userInput.campDirector,
                        {
                            directorName,
                            directorEmail,
                            directorPhone,
                            directorAddress
                        }
                    ]
                });
                setUserInput({
                    directorName: '',
                    directorEmail: '',
                    directorPhone: '',
                    directorAddress: '',
                });
                setDisableAddDirctor(true);

            }else{
                setError({directorEmail: 'Email is required'});
            }
            
        }
        
    }





    useEffect(() => {
        if(props.mode == 2){
            if(isNewDirector){

            let {
                campName,
                campCode,
                email,
                phone,
                address,
                campDirector,
                icon,
                registrationTarget
            } = userInput;

            let newCampDirector = campDirector.filter(item => !('_id' in item));

            let params = new FormData();
            params.append('campId', props.campDetail._id);
            params.append('campName', campName);
            params.append('campCode', campCode);
            params.append('email', email);
            params.append('phone', phone);
            params.append('address', address);
            params.append('registrationTarget', registrationTarget);
            params.append('icon', icon);
            params.append('campDirector', JSON.stringify(newCampDirector));
            params.append('showInsurance', showInsurance);
            params.append('showProductEditButton', showProductEditButton);
            props.editCampAction(params).then(data => {
                notification.open({
                    message: 'Camp',
                    description: `Director add successfully`
                });
                let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
                props.campDetailAction(query);
                setIsNewDirector(false);
            });
            }
        }
    }, [isNewDirector])




    const handleFileChange = e => {
        setError({icon: ''})
        let reader = new FileReader();
        let file = e.target.files[0];
    
        setUserInput({icon: file})
        
        reader.addEventListener('load', () => {
            setUserInput({iconPreview: reader.result})
        }, false);
        if (file) { reader.readAsDataURL(file) }
    }



    const handleStatusChange = (c_state, s_state) => {
        let _c_state = {...c_state};
        _c_state.status = c_state.status == 1 ? 2 : 1;
        s_state(_c_state)
    }
    const handleShowInsuranceChange = (s_state) => {
        s_state(prev => !prev)
    }

    const handleshowProductEditButtonChange = (s_state) => {
        s_state(prev => !prev)
    }
    
    const handleRateChange = (val, c_state, s_state) => {
        let _c_state = {...c_state};
        _c_state.percentage = val;
        _c_state.inputErr = '';
        s_state(_c_state)
    }


    const handleFeeChange = (val, c_state, s_state) => {
        let _c_state = {...c_state};
        _c_state.taxRate = val;
        _c_state.inputErr = '';
        s_state(_c_state)
    }

    const handleNumberOfPayments = (e, c_state, s_state) => {
        let val = e.target.value;
        let _c_state = {...c_state};
        _c_state.noOfTimesPayment = val;
        _c_state.inputErr = '';
        s_state(_c_state)
    }



    const handleValidate = () => {
        let validate = true;
        let { campName, email, phone, address, iconPreview, campDirector, directorName, directorEmail, registrationTarget } = userInput;


        if(campName == ''){
            setError({campName: 'Camp name is required'});
            validate = false
        }

        if(email == ''){
            setError({email: 'Email is required'});
            validate = false
        }

        if(phone == ''){
            setError({phone: 'Phone Number is required'});
            validate = false
        }else if(phone.length < 10){
            setError({phone: 'Phone Number is not valid'});
            validate = false
        }else if(phone.length > 15){
            setError({phone: 'Phone Number is not valid'});
            validate = false
        }


        if(address == ''){
            setError({address: 'Address is required'});
            validate = false
        }

        if(registrationTarget == ''){
            setError({registrationTarget: 'Registration target is required'});
            validate = false
        }

        if(iconPreview == ''){
            setError({icon: 'Icon is required'});
            validate = false
        }


        if(campDirector.length === 0 && directorName == ''){
            setError({directorName: 'Director name is required'});
            validate = false
        }

        if(campDirector.length === 0 && directorEmail == ''){
            setError({directorEmail: 'Email is required'});
            validate = false
        }else if(campDirector.length === 0 && !directorEmail){
            setError({directorEmail: 'Email is not valid'});
            validate = false
        }

        if(creditCardRate.percentage == 'undefined' || creditCardRate.percentage == null){
            let _creditCardRate = {...creditCardRate};
            _creditCardRate.inputErr = 'Percentage is Required';
            setCreditCardRate(_creditCardRate);
            validate = false
        }

        if(creditCardRate.taxRate == 'undefined' || creditCardRate.taxRate == null){
            let _creditCardRate = {...creditCardRate};
            _creditCardRate.inputErr = 'Tax rate is Required';
            setCreditCardRate(_creditCardRate);
            validate = false
        }

        if(byCheque.taxRate == 'undefined' || byCheque.taxRate == null){
            let _byCheque = {...byCheque};
            _byCheque.inputErr = 'Tax rate is Required';
            setByCheque(_byCheque);
            validate = false
        }


        if(achTransferRate.percentage == 'undefined' || achTransferRate.percentage == null){
            let _achTransferRate = {...achTransferRate};
            _achTransferRate.inputErr = 'Percentage is Required';
            setAchTransferRate(_achTransferRate);
            validate = false
        }

        if(achTransferRate.taxRate == 'undefined' || achTransferRate.taxRate == null){
            let _achTransferRate = {...achTransferRate};
            _achTransferRate.inputErr = 'Tax rate is Required';
            setAchTransferRate(_achTransferRate);
            validate = false
        }


        if(splitPaymentRate.percentage == 'undefined' || splitPaymentRate.percentage == null){
            let _splitPaymentRate = {...splitPaymentRate};
            _splitPaymentRate.inputErr = 'Percentage is Required';
            setSplitPaymentRate(_splitPaymentRate);
            validate = false
        }

        if(splitPaymentRate.taxRate == 'undefined' || splitPaymentRate.taxRate == null){
            let _splitPaymentRate = {...splitPaymentRate};
            _splitPaymentRate.inputErr = 'Tax rate is Required';
            setSplitPaymentRate(_splitPaymentRate);
            validate = false
        }
     

        return validate;
    }


    const handleSubmit = e => {
        e.preventDefault();

        if(handleValidate()){
            let {
                campName,
                campCode,
                email,
                phone,
                address,
                campDirector,
                icon,
                directorName,
                directorEmail,
                directorPhone,
                directorAddress,
                registrationTarget
            } = userInput;

            let params = new FormData();
            params.append('campId', props.campDetail._id ? props.campDetail._id : '');
            params.append('campName', campName);
            params.append('campCode', campCode);
            params.append('email', email);
            params.append('phone', phone);
            params.append('address', address);
            params.append('registrationTarget', registrationTarget);
            params.append('icon', icon);
            params.append('achTransferRate', JSON.stringify(achTransferRate));
            params.append('splitPaymentRate', JSON.stringify(splitPaymentRate));
            params.append('creditCardRate', JSON.stringify(creditCardRate));
            params.append('byCheque', JSON.stringify(byCheque));
            params.append('showInsurance', showInsurance);
            params.append('showProductEditButton', showProductEditButton);



            if(props.mode){
                let newCampDirector = campDirector.filter(item => !('_id' in item));
                params.append('campDirector', JSON.stringify(newCampDirector));
                props.editCampAction(params).then(data => {
                    notification.open({
                        message: 'Camp',
                        description: `Camp edit successfully`
                    });
                    let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
                    props.campDetailAction(query);
                });
            }else{
                params.append('campDirector', (campDirector.length === 0 && (directorName != '' && directorEmail != '')) ? JSON.stringify([{directorName, directorEmail, directorPhone, directorAddress}]) : JSON.stringify(campDirector));
                props.addCampAction(params).then(data => {
                    if(data.statusCode){
                    setUserInput({
                        isRedirect: true,
                        newCampId: data.responseData.campId
                    });
                    props.campModeAction(2);

                    notification.open({
                        message: 'Camp',
                        description: `Camp created successfully`
                    });

                    }else{
                    setResError(data.error.responseMessage)
                    }

                    return data;
                    
                }).then(data => {
                    if(data.statusCode){
                        let query = `?campId=${data.responseData.campId}&pageNo=${props.programPageNumner - 1}&limit=10`;
                        props.campDetailAction(query);
                    }
                });
            }

        }
    }



    useEffect(() => {
        let campMode = url.slice(0, url.indexOf('-'));
        switch(campMode){
            case 'edit': props.campModeAction(2);
            break
            case 'view': props.campModeAction(1);
            break
            default: props.campModeAction(0);
        }

        if(campMode){
            let query = `?campId=${url.slice(url.indexOf('-') + 1)}&pageNo=${props.programPageNumner - 1}&limit=10`;
            props.campDetailAction(query);
        }
        
        props.clearLoaderAction()
        props.setBreadcrumbAction(params);



        return () => {
            props.clearCampDetailAction();
        }

    }, [editRoute, props.programPageNumner]);


    useEffect(() => {
        if(props.campDetail){
            let query = `?campCode=${props.campDetail.campCode}`;
            props.personnalByCampCodeAction(query);
        }

    }, [props.campDetail])




    useEffect(() => {
            let { campCode, campDirector, campName, icon, phone, address, email, numberOfProgram, registrationTarget, achTransferRate, byCheque, creditCardRate, splitPaymentRate, showInsurance } = props.campDetail;
            if(props.campDetail){
                setUserInput({
                    campCode,
                    campName,
                    iconPreview:
                    icon,
                    phone,
                    address,
                    email,
                    campDirector: campDirector ? campDirector: '',
                    numberOfProgram,
                    registrationTarget
                });

                setAchTransferRate(achTransferRate || {});
                setByCheque(byCheque || {});
                setCreditCardRate(creditCardRate || {});
                setSplitPaymentRate(splitPaymentRate || {});
                setShowInsurance(showInsurance);
                setshowProductEditButton(props.campDetail?.showProductEditButton ? true : false)
            }
            


    }, [props.campDetail])

    let { mode } = props;
    return(
        <div className="page_wrapper">
            {props.loader ? <MainLoader /> : ''}
            {userInput.isRedirect ? <Redirect to={`/camps/edit-${userInput.newCampId}`} /> : ''}
            <div className="page_card">

                <div className={`${(mode == 1) ? 'd-flex align-items-center justify-content-between' : ''} page_card_header`}>
                    <h4>{mode ? userInput.campName : 'Add Camps'}</h4>

                    {(mode == 1) ?
                    <div className="btn_group">
                        <button className="btn btn-primary mr-3">Camp Counselor</button>
                        <Link className="btn btn-primary" onClick={() => setEditRoute(true)} to={`/camps/edit-${props.campDetail._id}`}>Edit Camp</Link>
                    </div> : ''}

                </div>

                <div className="page_card_body">
                   
                    <div className="add_camp_wrap">

                            <div className="row">




                                <div className="col-xl-3">
                                    <div className="camp_logo_upload">
                                        {(mode == 1) ?
                                        <img src={userInput.iconPreview} alt="icon" /> :
                                        <Fragment>
                                            <input type="file" onChange={handleFileChange} accept="image/x-png,image/jpeg" />
                                            {userInput.iconPreview ? <img src={userInput.iconPreview} alt="icon" /> : ''}
                    
                                            {userInput.iconPreview ? <i className="icon-create" />
                                                                    : <div><i className="icon-button" /><p>Upload Image</p></div>}
                                            <div className="invalid_feild">{error.icon}</div>
                                        </Fragment> 
                                        }
                                    </div>
                                    {(mode == 1) ? '' : <h5 className="text-center mt-2">Camp Logo</h5>}
                                    <div className="text-center">
                                        <small>100x100</small>
                                    </div>
                                </div>






                                {
                                    (mode == 1) ?
                                    <div className="col-md-5">
                                        <p><b>Provider Name</b> {userInput.campName}</p>
                                        <p><b>Address</b> {userInput.address}</p>
                                        <p><b>Total Provider Programs</b> {userInput.numberOfProgram}</p>
                                        {/* <p><b>Camp Director</b> {userInput.campDirector.length ? userInput.campDirector[0].directorName : ''}</p>
                                        <p><b>Director Email</b> {userInput.campDirector.length ? userInput.campDirector[0].directorEmail : ''}</p>
                                        <p><b>Director Contact</b> {userInput.campDirector.length ? userInput.campDirector[0].directorPhone : ''}</p> */}
                                    </div>

                                    :


                                    <div className="col-md-6">

                                    <div className="row">
                                        
                                        <div className="form-group col-md-6">
                                            <label>Provider Name*</label>

                                            <input
                                                type="text"
                                                className={`form-control ${error.campName ? 'is-invalid' : ''}`}
                                                name="campName"
                                                placeholder="Provider Name"
                                                value={userInput.campName}
                                                onChange={handleChange} />

                                            {error.campName ? <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip>{error.campName}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                                        </div>






                                        <div className="form-group col-md-6">
                                            <label>Org Code (Auto generated)</label>
                                            <input
                                                type="text"
                                                value={userInput.campCode}
                                                readOnly
                                                className="form-control" />
                                            {/* <input
                                                type="email"
                                                className={`form-control ${error.campCode ? 'is-invalid' : ''}`}
                                                name="campCode"
                                                placeholder="Camp Code"
                                                value={userInput.campCode}
                                                onChange={handleChange} />

                                            {error.campCode ? <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip>{error.campCode}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''} */}
                                        </div>



                                          
                                        <div className="form-group col-md-6">
                                            <label>Provider Email*</label>

                                            <input
                                                type="text"
                                                className={`form-control ${error.email ? 'is-invalid' : ''}`}
                                                name="email"
                                                placeholder="Provider Email"
                                                value={userInput.email }
                                                onChange={handleChange} />

                                            {error.email ? <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip>{error.email}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                                        </div>






                                        <div className="form-group col-md-6">
                                            <label>Provider Phone*</label>
                                            
                                            <div className="cm_phone_field">
                                                <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                                <PhoneInput
                                                    country="US"
                                                    className={`form-control ${error.phone ? 'is-invalid' : ''}`}
                                                    placeholder="Phone Number"
                                                    name="phone"
                                                    value={userInput.phone}
                                                    onChange={e => handlePhoneChange(e, 'phone')}/>
                                            </div>
                                           

                                            {error.phone ? <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip>{error.phone}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                                        </div>



                                        <div className="form-group col-md-6">
                                            <label>Provider Address*</label>
                                            <input
                                                type="text"
                                                className={`form-control ${error.address ? 'is-invalid' : ''}`}
                                                name="address"
                                                placeholder="Provider Address"
                                                value={userInput.address}
                                                onChange={handleChange} />

                                            {error.address ? <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip>{error.address}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                                        </div>



                                        <div className="form-group col-md-6">
                                            <label>Registration Target*</label>
                                            <InputNumber
                                                min={0}
                                                className={`form-control ${error.registrationTarget ? 'is-invalid' : ''}`}
                                                value={userInput.registrationTarget}
                                                placeholder="Registration Target"
                                                onChange={handleRegistrationTarget} />

                                            {error.registrationTarget ? <OverlayTrigger
                                                                placement="left"
                                                                overlay={<Tooltip>{error.registrationTarget}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                                        </div>






                                    </div>
                                   
                        
                                </div>

                                
                                }

                                    <div className={`${mode == 1 ? 'col-xl-4' : 'col-xl-3'}`}>

                                        {
                                            mode == 1 ? <div>
                                                <p><b>Credit Card Rate</b> {creditCardRate.percentage}% + ${creditCardRate.taxRate} {creditCardRate.status == 1 ? <span className='pay_active'>Active</span> : ''}</p>
                                                <p><b>ACH Transfer Rate</b> {achTransferRate.percentage}% + ${achTransferRate.taxRate} {achTransferRate.status == 1 ? <span className='pay_active'>Active</span> : ''}</p>
                                                <p><b>Split Payment</b> {splitPaymentRate.percentage}% + ${splitPaymentRate.taxRate} {splitPaymentRate.status == 1 ? <span className='pay_active'>Active</span> : ''}</p>
                                                <p><b>Number of Payments</b> {splitPaymentRate.noOfTimesPayment}</p>
                                                <p><b>Paper Check</b> ${byCheque.taxRate} {byCheque.status == 1 ? <span className='pay_active'>Active</span> : ''}</p>
                                            </div> : <Fragment>
                                                                            <div className='st_wrapper'>

                                                                            <div className="cm_swich_wrap">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={creditCardRate.status == 1}
                                                                                    onChange={() => handleStatusChange(creditCardRate, setCreditCardRate)} />
                                                                                <span />
                                                                            </div>

                                                                                <label>Credit Card Rate</label>

                                                                                <div className='st_input'>
                                                                                    <CustomNumber
                                                                                        className={`mr-3`}
                                                                                        change={e => handleRateChange(e, creditCardRate, setCreditCardRate)}
                                                                                        formatter={value => `${value}%`}
                                                                                        value={creditCardRate.percentage}
                                                                                        precision={creditCardRate.percentage > 0 ? 2 : 0}
                                                                                        min={0}
                                                                                        max={100} />

                                                                                    <b>+</b>

                                                                                    <CustomNumber
                                                                                        className="ml-3"
                                                                                        change={e => handleFeeChange(e, creditCardRate, setCreditCardRate)}
                                                                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                        value={creditCardRate.taxRate}
                                                                                        precision={creditCardRate.taxRate > 0 ? 2 : 0}
                                                                                        min={0} />

                                                                                
                                                                                </div>

                                                                                {creditCardRate.inputErr ? <span className='my_eror'>{creditCardRate.inputErr}</span> : ''}
                                                                            </div>

                                                                            <div className='st_wrapper'>

                                                                            <div className="cm_swich_wrap">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={achTransferRate.status == 1}
                                                                                    onChange={() => handleStatusChange(achTransferRate, setAchTransferRate)} />
                                                                                <span />
                                                                            </div>


                                                                                <label>ACH Transfer Rate</label>

                                                                                <div className='st_input'>
                                                                                        <CustomNumber
                                                                                            className="mr-3"
                                                                                            change={e => handleRateChange(e, achTransferRate, setAchTransferRate)}
                                                                                            formatter={value => `${value}%`}
                                                                                            value={achTransferRate.percentage}
                                                                                            precision={achTransferRate.percentage > 0 ? 2 : 0}
                                                                                            min={0}
                                                                                            max={100} />

                                                                                        <b>+</b>

                                                                                    <CustomNumber
                                                                                        className="ml-3"
                                                                                        change={e => handleFeeChange(e, achTransferRate, setAchTransferRate)}
                                                                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                        value={achTransferRate.taxRate}
                                                                                        precision={achTransferRate.taxRate > 0 ? 2 : 0}
                                                                                        //pHolder="Discount"
                                                                                        min={0} />
                                                                                </div>

                                                                                {achTransferRate.inputErr ? <span className='my_eror'>{achTransferRate.inputErr}</span> : ''}
                                                                            </div>

                                                                            <div className='st_wrapper'>

                                                                            <div className="cm_swich_wrap">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={splitPaymentRate.status == 1}
                                                                                    onChange={() => handleStatusChange(splitPaymentRate, setSplitPaymentRate)} />
                                                                                <span />
                                                                            </div>

                                                                                <label>Split Payment</label>

                                                                                <div className='st_input'>
                                                                                    <CustomNumber
                                                                                        className="mr-3"
                                                                                        change={e => handleRateChange(e, splitPaymentRate, setSplitPaymentRate)}
                                                                                        formatter={value => `${value}%`}
                                                                                        value={splitPaymentRate.percentage}
                                                                                        precision={splitPaymentRate.percentage > 0 ? 2 : 0}
                                                                                        min={0}
                                                                                        max={100} />

                                                                                        <b>+</b>

                                                                                    <CustomNumber
                                                                                        className="ml-3"
                                                                                        change={e => handleFeeChange(e, splitPaymentRate, setSplitPaymentRate)}
                                                                                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                        value={splitPaymentRate.taxRate}
                                                                                        precision={splitPaymentRate.taxRate > 0 ? 2 : 0}
                                                                                        //pHolder="Discount"
                                                                                        min={0} />
                                                                                </div>

                                                                                {splitPaymentRate.inputErr ? <span className='my_eror'>{splitPaymentRate.inputErr}</span> : ''}
                                                                                <div className="cm_swich_wrap">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={creditCardRate.status == 1}
                                                                                    onChange={() => handleStatusChange(creditCardRate, setCreditCardRate)} />
                                                                                <span />
                                                                            </div>
                                                                            </div>
                                            </Fragment>
                                        }

                                        

                                    </div>
                            </div>




                                   


                        





                                {
                                    (mode == 1) ? '' :

                                    <div className="row">

                                    <div className="col-xl-3" />

                                    <div className="col-xl-6">

                                            <div className="row">

                                                <div className="form-group col-md-6">
                                                    <label>Camp Director Name*</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${error.directorName ? 'is-invalid' : ''}`}
                                                        name="directorName"
                                                        placeholder="Camp Director Name"
                                                        value={userInput.directorName}
                                                        onChange={handleChange} />

                                                    {error.directorName ? <OverlayTrigger
                                                                        placement="left"
                                                                        overlay={<Tooltip>{error.directorName}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                                                </div>





                                                <div className="form-group col-md-6">
                                                    <label>Email*</label>
                                                    <input
                                                        type="email"
                                                        className={`form-control ${error.directorEmail ? 'is-invalid' : ''}`}
                                                        name="directorEmail"
                                                        placeholder="Email"
                                                        value={userInput.directorEmail}
                                                        onChange={handleChange} />

                                                    {error.directorEmail ? <OverlayTrigger
                                                                        placement="left"
                                                                        overlay={<Tooltip>{error.directorEmail}</Tooltip>}><span className="error_tootltip_label" /></OverlayTrigger> : ''}
                                                </div>







                                                <div className="form-group col-md-6">
                                                    <label>Phone Number</label>
                                                    <div className="cm_phone_field">
                                                        <img className="us_flag" src={require('../../assets/img/united-states-flag-icon.svg')} />
                                                        <PhoneInput
                                                            country="US"
                                                            className="form-control"
                                                            placeholder="Phone Number"
                                                            name="directorPhone"
                                                            value={userInput.directorPhone}
                                                            onChange={e => handlePhoneChange(e, 'directorPhone')}/>
                                                    </div>
                                                    
                                                </div>





                                                <div className="form-group col-md-6">
                                                    <label>Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="directorAddress"
                                                        placeholder="Director Address"
                                                        value={userInput.directorAddress}
                                                        onChange={handleChange} />

                                                    
                                                </div>

                                                <div className="col-md-12 pt-2">
                                                    <button disabled={disableAddDirctor} className="btn btn-outline-primary " onClick={handleAddDirector}><i className="icon-plus" /> Add Camp Director</button>
                                                    <div className="invalid_feild">{error.campDirector}</div>
                                                </div>

                                            </div>

                                           

                                        </div>


                                        <div className="col-xl-3">

                                        <div className='st_wrapper'>
                                            <label>Number of Payments</label>

                                            <div className='st_input'>
                                                <select
                                                    value={splitPaymentRate.noOfTimesPayment}
                                                    onChange={e => handleNumberOfPayments(e, splitPaymentRate, setSplitPaymentRate)} className='form-control rounded-0'>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                </select>
                                            </div>

                                           
                                        </div>

                                        <div className='st_wrapper'>
                                            <div className="cm_swich_wrap">
                                                <input
                                                    type="checkbox"
                                                    checked={byCheque.status == 1}
                                                    onChange={() => handleStatusChange(byCheque, setByCheque)} />
                                                <span />
                                            </div>
                                            <label>Paper Check</label>

                                            <div className='st_input'>
                                                <CustomNumber
                                                    className="ml-0"
                                                    change={e => handleFeeChange(e, byCheque, setByCheque)}
                                                    formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    value={byCheque.taxRate}
                                                    precision={byCheque.taxRate > 0 ? 2 : 0}
                                                    min={0} />
                                            </div>

                                            {byCheque.inputErr ? <span className='my_eror'>{byCheque.inputErr}</span> : ''}
                                        </div>
                                        <div className="st_wrapper">
                                                    <label>Show Insurance</label>
                                                    <div className="cm_swich_wrap">
                                                        <input
                                                            type="checkbox"
                                                            checked={showInsurance}
                                                            onChange={() => handleShowInsuranceChange(setShowInsurance)} />
                                                        <span />
                                                    </div>
                                        </div>
                                        <div className="st_wrapper">
                                                    <label>Show Product Edit Button</label>
                                                    <div className="cm_swich_wrap">
                                                        <input
                                                            type="checkbox"
                                                            checked={showProductEditButton}
                                                            onChange={() => handleshowProductEditButtonChange(setshowProductEditButton)} />
                                                        <span />
                                                    </div>
                                        </div>


                                       

                                    </div>


                                        

                                    

                                </div>
                                }

                                {resError ? <h5 className="res_error">{resError}</h5> : ''}
                                


                                {
                                        userInput.campDirector.length ? (
                                            <Fragment>
                                            <h6>Camp Directors</h6>
                                            <div className="mt-3 mb-4 table_responsive_wrap">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                            <th>Director Name</th>
                                                            <th>Email</th>
                                                            <th>Phone Number</th>
                                                            <th>Address</th>
                                                            <th>Action</th>
                                                        </thead>

                                                        <tbody>
                                                            {userInput.campDirector.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td>{item.directorName}</td>
                                                                    <td>{item.directorEmail}</td>
                                                                    <td>{item.directorPhone}</td>
                                                                    <td>{item.directorAddress}</td>
                                                                    <td><i onClick={() => handleConfirmModal(item._id, i)} className="icon-delete" /></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            </Fragment>
                                        ) : ''
                                    }

                    </div>

                    <CampProgramList />
                    
                    <ConfirmModal
                        show={confirmModal}
                        hideModal={() => setConfirmModal(false)}
                        msg="Are you sure you want to delete?"
                        action={handleDelete} />

                    </div>

            </div>


            <div className="d-flex justify-content-between mb-4 mt-4">
                <Link to='/camps' className="btn btn-outline-primary"><i className="icon-left-arrow" /> GO Back</Link>
                {(mode == 1) ? '' : <button onClick={handleSubmit} className="btn btn-primary">Submit</button>}
                
            </div>

        </div>
    )
}


const mapStateToProps = state => {
    let { loader, campDetail, mode, programPageNumner } = state.camp;
    return {
        loader,
        campDetail,
        mode,
        programPageNumner
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
    clearLoaderAction: () => dispatch(clearLoaderAction()),
    clearCampDetailAction: () => dispatch(clearCampDetailAction()),
    addCampAction: params => dispatch(addCampAction(params)),
    campModeAction: params => dispatch(campModeAction(params)),
    campDetailAction: params => dispatch(campDetailAction(params)),
    editCampAction: params => dispatch(editCampAction(params)),
    personnalByCampCodeAction: params => dispatch(personnalByCampCodeAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Camp);
